import { ConsolidatedReconciliationReportModule } from './modules/consolidated-reconciliation-report/consolidated-reconciliation-report.module';

import { Error401Component } from './shared/components/error-pages/error401/error401.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PreloadAllModules } from '@angular/router';
import { Error404Component } from './shared/components/error-pages/error404/error404.component';
import { CustomPreloadingModuleService } from './shared/services/custom-preloading-module.service';
import { LoginGuard } from './shared/guards/login.guard';

const routes: Routes = [

  {
    path: 'login',
    data: { preload: true },
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'dashboard',
    canActivate: [LoginGuard],
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'document',
    loadChildren: () => import('./modules/document-detailed-view/document-detailed-view.module').then(m => m.DocumentDetailedViewModule)
  },

  {
    path: 'trade-finance-document',
    loadChildren: () => import('./modules/dashboard/component-pieces/trade-finance/trade-finance-details-view/trade-finance-detailed-view.module').then(m => m.TradeFinanceDetailedViewModule)
  },
  {
    path: 'rule-engine',
    canActivate: [LoginGuard],
    loadChildren: () => import('./modules/rule-engine/rule-engine.module').then(m => m.RuleEngineModule)
  },
  {
    path: 'data-source',
    canActivate: [LoginGuard],
    loadChildren: () => import('./modules/data-source/data-source.module').then(m => m.DataSourceModule)
  },
  {
    path: 'user',
    canActivate: [LoginGuard],
    loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule)
  },
  {
    path: 'custom-module',
    canActivate: [LoginGuard],
    loadChildren: () => import('./modules/custom-module/custom-module.module').then(m => m.CustomModuleModule)
  },
  {
    path: 'audit-report',
    canActivate: [LoginGuard],
    loadChildren: () => import('./modules/consolidated-reconciliation-report/consolidated-reconciliation-report.module').then(m => m.ConsolidatedReconciliationReportModule)
  },

  {
    path: 'registration',
    data: { preload: true },
    loadChildren: () => import('./modules/user-registration-form/register.module').then(m => m.RegisterModule)
  },

  { path: 'error-401', component: Error401Component },
  { path: 'error-404', component: Error404Component },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/error-404', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,
      {
        useHash: false,
        // preloadingStrategy: PreloadAllModules 
        preloadingStrategy: CustomPreloadingModuleService
      }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
