
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UppercaseFirstWordPipe } from './uppercase-first-word.pipe';
import { BooleanToTitlecasePipe } from './boolean-to-titlecase.pipe';
import { DateConverterPipe } from './date-converter.pipe';
import { AmountSeparatedByCommaPipe } from './amount-separated-by-comma.pipe';
import { TradeDateConverterPipe } from './trade-date-converter.pipe';
import { AvatarColorPickerPipe } from './avatar-color-picker.pipe';

@NgModule({
  declarations: [
    UppercaseFirstWordPipe,
    BooleanToTitlecasePipe,
    DateConverterPipe,
    AmountSeparatedByCommaPipe,
    TradeDateConverterPipe,
    AvatarColorPickerPipe,

  ],
  imports: [
    CommonModule
  ],

  exports: [
    UppercaseFirstWordPipe,
    BooleanToTitlecasePipe,
    AmountSeparatedByCommaPipe,
    TradeDateConverterPipe,
    AvatarColorPickerPipe
  ]
})
export class SharedPipeModule { }
