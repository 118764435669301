import { AddFieldSet } from './../models/add_field_set.interface';
import { BaseApiUrl } from 'src/app/shared/base_api/base-api-urls';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CookiesService } from 'src/app/shared/services/cookies.service';
import { LoginService } from '../../login/services/login.service';
import jwt_decode from "jwt-decode";
import { UserSettings } from '../models/user_setting.interface';
import { UserSettingsVariables } from 'src/app/shared/variables/userSettings_variables';

@Injectable({
  providedIn: 'root'
})
export class UserService {


  public companyName: string;
  public username: string;
  public userId: string;
  public userrole: string;

  public selectedDocType = {
    text: 'Invoices',
    param: 'Invoices',
    labelKey: 'invoices'
  };

  public creditPurchaseHistory$ = new BehaviorSubject(null);

  public documentType$ = new BehaviorSubject(JSON.parse(this.cookiesService.getCookieByName("selectedDocType")) || this.selectedDocType);

  public userSettings: UserSettings = UserSettingsVariables;


  public defaultDocValues = ['invoices', 'receipts', 'purchaseorders', 'autorejected', 'tabs', 'rfq', 'nach', 'insurances'];
  public userSettings$ = new BehaviorSubject(this.userSettings);

  constructor(
    private http: HttpClient,
    private cookiesService: CookiesService,
    private loginService: LoginService
  ) { }

  public getUserDetailsFromSessionOrCookie(): void {
    this.companyName = this.cookiesService.getCookieByName("companyName");
    this.userrole = this.cookiesService.getCookieByName("userRole");
    this.username = this.cookiesService.getCookieByName("userName");
    this.userId = this.cookiesService.getCookieByName("userId");

  }


  /////// For Getting Access Token from refresh Token: Starts //////

  public isAccessTokenExpired(): void {
    let accessToken = this.cookiesService.getCookieByName("accessToken");

    if (accessToken) {
      let accessTokenDetails: any = jwt_decode(accessToken);

      let expiryTimeInSeconds = accessTokenDetails.exp;
      let issueTimeInSeconds = accessTokenDetails.iat;
      let timeDiffrenceInExpiry = expiryTimeInSeconds - issueTimeInSeconds;
      let expiryTimeInMinutes = timeDiffrenceInExpiry / 60;

      let timeLeftForAccessTokenExpiry = ((1000 * expiryTimeInSeconds) - (new Date().getTime()));

      if (timeLeftForAccessTokenExpiry < (10 * 60 * 1000)) {
        this.getAccessTokenFromRefreshToken();
      } else {
      }
    }
  }

  public getAccessTokenFromRefreshToken(): void {
    let refereshToken = JSON.parse(this.cookiesService.getCookieByName("refreshToken"));
    let companyName = this.cookiesService.getCookieByName("companyName");

    this.loginService.getAccessTokenFromRefreshToken(refereshToken, companyName)
      .subscribe(
        {
          next: (result) => {
            let accessToken = result.access_token;
            if (this.cookiesService.checkCookie('accessToken')) {
              this.cookiesService.deleteCookie('accessToken');
              this.cookiesService.setCookie("accessToken", JSON.stringify(accessToken), 1);
            } else {
              this.cookiesService.setCookie("accessToken", JSON.stringify(accessToken), 1);
            }
          },
          error: (error) => { },
          complete: () => { }
        }
      )
  }

  /////// For Getting Access Token from refresh Token: Ends //////

  public getUserDetails(): any {
    this.getUserDetailsFromSessionOrCookie();
    const fd = new FormData();
    fd.append('company_name', this.companyName);
    return this.http.post(BaseApiUrl.GetUserDetails, fd);
  }

  // create new user
  public createNewUser(userName: string, password: string, userRole: string): any {
    this.getUserDetailsFromSessionOrCookie();
    const fd = new FormData();
    fd.append('username', userName);
    fd.append('password', password);
    fd.append('role', userRole);
    fd.append('company_name', this.companyName);
    return this.http.post(BaseApiUrl.RegisterUserDetails, fd, {
      responseType: 'text'
    });
  }

  // update existing user information
  public updateUserDetails(data) {
    this.getUserDetailsFromSessionOrCookie();
    const fd = new FormData();

    fd.append('data', JSON.stringify(data));
    fd.append('company_name', this.companyName);
    return this.http.post(BaseApiUrl.UpdateUserDetails, fd, {
      responseType: 'text'
    });
  }

  public getLabels(): Observable<any> {
    this.getUserDetailsFromSessionOrCookie();
    this.isAccessTokenExpired();

    const fd = new FormData();
    fd.append('company_name', this.companyName);
    return this.http.post(BaseApiUrl.GetUserSettings, fd);
  }

  public updateLabels(data, collection?): Observable<any> {
    this.getUserDetailsFromSessionOrCookie();
    this.isAccessTokenExpired();

    console.log("update user settings", data);

    const fd = new FormData();
    fd.append('data', JSON.stringify(data));
    fd.append('collection_name', 'CompanyMetadata');
    fd.append('company_name', this.companyName);
    return this.http.post(BaseApiUrl.UpdateUserSettings, fd, {
      responseType: 'text'
    });
  }

  public cancelSubscriptionPlan(subscriptionId: string): Observable<any> {
    this.getUserDetailsFromSessionOrCookie();
    this.isAccessTokenExpired();

    const fd = new FormData();

    fd.append('subscription_id', subscriptionId);
    fd.append('company_name', this.companyName);
    return this.http.post(BaseApiUrl.CancelSubscription, fd, {
      responseType: 'text'
    });
  }

  public upgradeSubscriptionPlan(subscriptionId: string, planCode: string): Observable<any> {
    this.getUserDetailsFromSessionOrCookie();
    this.isAccessTokenExpired();

    const fd = new FormData();

    fd.append('subscription_id', subscriptionId);
    fd.append('plan_code', planCode);
    return this.http.post(BaseApiUrl.UpgradeSubscription, fd, {
      responseType: 'text'
    });
  }

  public buySubscriptionPlanAndPurchaseCredits(planCode?: string, numberOfCredits?: any): Observable<any> {
    this.getUserDetailsFromSessionOrCookie();
    this.isAccessTokenExpired();

    const fd = new FormData();
    fd.append('company_name', this.companyName);
    fd.append('plan_code', planCode);
    fd.append('credits', numberOfCredits);
    return this.http.post(BaseApiUrl.RenewSubscription, fd, {
      responseType: 'text'
    });
  }

  public changedPlanSubscriptionToCredit(numberOfCredits?: any): Observable<any> {
    this.getUserDetailsFromSessionOrCookie();
    this.isAccessTokenExpired();

    const fd = new FormData();
    fd.append('company_name', this.companyName);
    fd.append('credits', numberOfCredits);
    return this.http.post(BaseApiUrl.RenewSubscription, fd, {
      responseType: 'text'
    });
  }

  public getCreditDetailsForUser(): Observable<any> {
    this.getUserDetailsFromSessionOrCookie();
    this.isAccessTokenExpired();
    const fd = new FormData();

    fd.append('company_name', this.companyName);

    return this.http.post(BaseApiUrl.CreditUserDetails, fd, { responseType: 'text' });
  }

  public getSubscriptionDetailsForUser(): Observable<any> {
    this.getUserDetailsFromSessionOrCookie();
    this.isAccessTokenExpired();
    const fd = new FormData();

    fd.append('company_name', this.companyName);

    return this.http.post(BaseApiUrl.SubscriptionUserDetails, fd, { responseType: 'text' });
  }

  public addNewFieldDocumentTypeWise(addNewField: AddFieldSet): Observable<any> {

    this.getUserDetailsFromSessionOrCookie();
    this.isAccessTokenExpired();
    let valuePatternList: any[] = [];
    valuePatternList.push(addNewField.single_value_attribute_value_pattern_list1, addNewField.single_value_attribute_value_pattern_list2)


    let addField = {
      company_name: this.companyName,
      document_type: addNewField.document_type,
      field_name: addNewField.field_name,
      single_value_attribute_label: addNewField.single_value_attribute_label,
      single_value_attribute_black_list_string: addNewField.single_value_attribute_black_list_string,
      single_value_attribute_black_list_pattern: addNewField.single_value_attribute_black_list_pattern,
      single_value_attribute_value_pattern_list: valuePatternList

    }

    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(addField);

    return this.http.post(BaseApiUrl.AddNewFieldDocumentTypeWise, body);
  }

  public updateNewFieldDocumentTypeWise(updateField: AddFieldSet): Observable<any> {

    this.getUserDetailsFromSessionOrCookie();
    this.isAccessTokenExpired();
    let valuePatternList: any[] = [];
    valuePatternList.push(updateField.single_value_attribute_value_pattern_list1, updateField.single_value_attribute_value_pattern_list2)


    let updateNewField = {
      company_name: this.companyName,
      document_type: updateField.document_type,
      field_label: updateField.field_label,
      single_value_attribute_label: updateField.single_value_attribute_label,
      single_value_attribute_black_list_string: updateField.single_value_attribute_black_list_string,
      single_value_attribute_black_list_pattern: updateField.single_value_attribute_black_list_pattern,
      single_value_attribute_value_pattern_list: valuePatternList,
      single_value_attribute_settings: updateField.single_value_attribute_settings
    }

    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(updateNewField);

    return this.http.post(BaseApiUrl.UpdateNewFieldDocumentTypeWise, body);
  }

}
