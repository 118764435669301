

export const CompaniesDetails = {
  KlearStack: {
    organizationName: 'KlearStack',
    isCompanySpecificSettings: true,
    logo_sm: {
      url: 'assets/images/logo.png',
      width: '65%'
    },
    logo_md: {
      url: 'assets/images/login/klearstack_logo.png',
      width: '25rem'
    },
    logo_bg: {
      url: 'assets/images/login/machine_image.png',
      width: '65%'
    },
    termAndConditionText: 'I accept the terms and conditions.',
    termAndConditionText_Url: 'https://www.klearstack.com/terms-and-conditions/',
    favIconUrl: '',
    isFreeTrial: true,
    isFavIcon: true,
    errorPageLogo: true,
  },

  WhiteLabel: {
    organizationName: window.location.hostname,
    isCompanySpecificSettings: false,
    logo_sm: {
      url: '',
      width: '0%'
    },
    logo_md: {
      url: '',
      width: '0%'
    },
    logo_bg: {
      url: '',
      width: '0%'
    },
    termAndConditionText: 'I accept the terms and conditions.',
    termAndConditionText_Url: '',
    favIconUrl: '',
    isFreeTrial: false,
    isFavIcon: true,
    errorPageLogo: false
  },

  Xtracuity_kmbh: {
    organizationName: 'Xtracuity',
    isCompanySpecificSettings: true,
    logo_sm: {
      url: '',
      width: '65%'
    },
    logo_md: {
      url: 'assets/images/organization_logo/extracuity_kmbs/Xtracuity_Logo.svg',
      width: '85%'
    },
    logo_bg: {
      url: 'assets/images/organization_logo/extracuity_kmbs/loginPage_left_image.jpg',
      width: '80%'
    },
    termAndConditionText: 'I accept the terms and conditions.',
    termAndConditionText_Url: '',
    favIconUrl: 'assets/images/organization_logo/extracuity_kmbs/extacuity_favicon.png',
    isFreeTrial: false,
    isFavIcon: true,
    errorPageLogo: false
  },

  RapidXtract: {
    organizationName: 'RapidXtract',
    isCompanySpecificSettings: true,
    logo_sm: {
      url: '/assets/images/basic_icons/home_icon.svg',
      width: '65%'
    },
    logo_md: {
      url: '/assets/images/organization_logo/rapid_xtract/rapid_xtract_logo.jpg',
      width: '80%'
    },
    logo_bg: {
      url: '',
      width: '65%'
    },
    termAndConditionText: 'I accept the terms and conditions.',
    termAndConditionText_Url: '',
    favIconUrl: '/assets/images/organization_logo/rapid_xtract/rapid_xtract_logo_2.jpg',
    isFreeTrial: false,
    isFavIcon: true,
    errorPageLogo: false
  },

  TWI: {
    organizationName: 'TWI- ',
    isCompanySpecificSettings: true,
    logo_sm: {
      url: 'assets/images/organization_logo/TWI/twi_logo.svg',
      width: '65%'
    },
    logo_md: {
      url: 'assets/images/organization_logo/TWI/twi_logo.svg',
      width: '30%'
    },
    logo_bg: {
      url: 'assets/images/organization_logo/TWI/twi_logo.svg',
      width: '50%'
    },
    termAndConditionText: 'I accept the terms and conditions.',
    termAndConditionText_Url: '',
    favIconUrl: 'assets/images/organization_logo/TWI/twi_logo.svg',
    isFreeTrial: false,
    isFavIcon: true,
    errorPageLogo: false
  },

  SuperDocs: {
    organizationName: 'SuperDocs',
    isCompanySpecificSettings: true,
    logo_sm: {
      url: 'assets/images/organization_logo/superdocs/superdocs_small_logo_200x200.png',
      width: '100%'
    },
    logo_md: {
      url: 'assets/images/organization_logo/superdocs/superdocs_medium_logo_800x200.png',
      width: '20rem'
    },
    logo_bg: {
      url: 'assets/images/organization_logo/superdocs/superdocs_large_logo_2050x1350.png',
      width: '40rem'
    },
    termAndConditionText: 'I accept the terms and conditions.',
    termAndConditionText_Url: '',
    favIconUrl: '/assets/images/organization_logo/superdocs/superdocs_favicon_32x32.png',
    isFreeTrial: false,
    isFavIcon: true,
    errorPageLogo: true
  },

}


// Note: Image Sizes:
// (1) favIcon : favIconUrl :
// (2) back button logo: logo_sm_Url
// (2) Login page logo: logo_md_Url  800 * 200
// (3) Login page left side page: logo_bg_Url
// For favIcon: Take a png file and use online converter to convter png to favicon png then use.
